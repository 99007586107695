.bg-blue {
    background-color: rgb(107,14,39); /*rgb(0,79,159);*/
}

.text-blue {
    color: rgb(107,14,39); /*rgb(0,79,159);*/
}

.hover\:bg-blue:hover {
    background-color: rgb(107,14,39);/*rgb(0,79,159);*/
}

.border-blue {
    border-color: rgb(107,14,39);/*rgb(0,79,159);*/
}
